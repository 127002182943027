
import { Component, VModel, Vue } from "vue-property-decorator";
import FormFieldText from "@/components/common/FormFieldText.vue";
import { set } from "lodash";

@Component({
  components: { FormFieldText },
})
export default class FormImppv extends Vue {
  @VModel({ type: Object, default: () => ({}) }) formData!: Record<string, any>;
  arInputs: string[] = [
    "Fecha",
    "Aerolinea",
    "Número de vuelo",
    "Aeropuerto de salida",
    "Hora prevista de salida",
    "Aeropuerto de llegada",
    "Hora prevista de llegada",
  ];

  onSetInput(sValue: string, sLabel: string): void {
    const obFormData = { ...this.formData };
    set(obFormData, sLabel, sValue);

    this.$emit("input", obFormData);
  }
}

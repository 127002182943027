import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { Order } from "@planetadeleste/vue-mc-orders";

const TypedModelMixin = ModelMixin as unknown as VueClass<ModelMixin<Order>>;

@Component
export default class Checkout extends Mixins(TypedModelMixin) {
  created() {
    this.obModelClass = Order;

    this.onCreated();
  }
}


import { AuthModule } from "@/store/auth";
import { Component, Mixins, Ref } from "vue-property-decorator";
import CheckoutMixin from "@/modules/checkout/mixins/Checkout";
import { Profile, UserAddressData } from "@planetadeleste/vue-mc-shopaholic";
import {
  CartComponentItemsData,
  Order,
  OrderRequestData,
  PaymentMethod,
  PaymentMethods,
} from "@planetadeleste/vue-mc-orders";
import { EventBus } from "@/services/event-bus";
import { CartModule } from "@/store/cart";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import {
  PaymentIntentConfirmParams,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import CartTable from "@/modules/cart/components/CartTable.vue";
import Stripe from "@/services/stripe";
import BSpinner from "@/components/bs/BSpinner.vue";
import IconCardOutline from "@/components/icons/IconCardOutline.vue";
import BRow from "@/components/bs/BRow.vue";
import BCol from "@/components/bs/BCol.vue";
import { route } from "@/services/laroute";
import EBtn from "@/components/common/EBtn.vue";
import { ValidationObserver } from "vee-validate";
import { filter, get, isEmpty, isNil, set, template, forEach } from "lodash";
import FormImppv from "@/modules/courses/components/FormImppv.vue";

type sAddressType = "billing" | "shipping";

@Component({
  components: {
    FormImppv,
    EBtn,
    BCol,
    BRow,
    IconCardOutline,
    BSpinner,
    CartTable,
    StripeElementPayment,
  },
})
export default class CheckoutForm extends Mixins(CheckoutMixin) {
  iStep = 0;
  bSave = true;
  bLoading = false;
  arComplete: number[] = [];
  obBillingData: UserAddressData | Record<string, any> = {};
  obShippingData: UserAddressData | Record<string, any> = {};
  obPaymentMethods: PaymentMethods = new PaymentMethods();
  obOrderProperty: Record<string, any> = {};
  iPaymentMethodId: number | null = null;

  pk = "";

  obElementOptions: StripeElementsOptions = {};

  obConfirmParams: PaymentIntentConfirmParams = {
    return_url: `${window.location.origin}/account/success`,
  };

  obOrderDescriptionData: Record<string, any> = {};

  sOrderDescription = "";

  @Ref("elPaymentRef") readonly obElementPayment!: StripeElementPayment;

  @Ref("observer") readonly obObserver!: InstanceType<
    typeof ValidationObserver
  >;

  get sBtnLabel(): "continue" | "confirm.order" {
    return this.iStep < 2 ? "continue" : "confirm.order";
  }

  get user(): Profile {
    return AuthModule.user;
  }

  get arAddress(): UserAddressData[] {
    return this.user ? this.user.get("address", []) : [];
  }

  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }

  get showForm(): boolean {
    if (
      isNil(this.cartItems) ||
      !this.cartItems.positions ||
      !this.cartItems.positions.length
    ) {
      return false;
    }

    const arPositionList = filter(
      this.cartItems.positions,
      (obItem) => get(obItem, "product.code") === "IMPPV"
    );

    return !isEmpty(arPositionList);
  }

  async mounted(): Promise<void> {
    // Load payment methods and autoselect first
    await this.obPaymentMethods.fetch();

    if (this.obPaymentMethods.length) {
      const obPaymentMethod = this.obPaymentMethods.first() as PaymentMethod;
      this.iPaymentMethodId = obPaymentMethod.id;
    }

    this.$_.delay(this.setAddressData, 500);
    this.pk = await Stripe.pk();
    this.bLoading = false;
  }

  nextStep(): void {
    if (this.iStep < 4) {
      if (!this.arComplete.includes(this.iStep)) {
        this.arComplete.push(this.iStep);
      }
      this.bSave = true;
      this.iStep++;
    }
  }

  async onSubmitStep(): Promise<void> {
    switch (this.iStep) {
      case 1:
        await this.onPaymentIntent();
        break;

      case 2:
        await this.onCompleteOrder();
        break;
    }
  }

  onLoading(bValue: boolean) {
    this.bLoading = bValue;
  }

  async onPaymentIntent(): Promise<void> {
    if (this.obElementPayment) {
      return;
    }

    this.bLoading = true;
    const obData = await new Stripe().paymentIntents();
    if (obData && obData.client_secret) {
      this.obElementOptions.clientSecret = obData.client_secret;
      this.nextStep();
    }
    this.bLoading = false;
  }

  onToken(obData: Record<string, any>): void {
    console.warn(obData);
  }

  onError(obData: Record<string, any>): void {
    console.info(obData);
  }

  async onCompleteOrder(): Promise<void> {
    const obOrderPropertyData = { ...this.obOrderProperty };
    let sDescription = "";

    if (this.sOrderDescription.length) {
      sDescription = this.sOrderDescription;
    }

    if (!isEmpty(this.obOrderDescriptionData)) {
      const sWrappDesc =
        "<br /><br /><hr /><dl class='row'><%= content %></dl>";
      const sItem = `<% forEach(items, function(value, label) {%>
        <dt class='col-xs-4'><%= label %></dt>
        <dd class='col-xs-8'><%= value %></dd>
        <% }); %>`;
      const fItems = template(sItem, { imports: { forEach } });
      const fWrapper = template(sWrappDesc);
      const sContent = fItems({ items: this.obOrderDescriptionData }) as string;
      sDescription += fWrapper({ content: sContent }) as string;
    }

    if (sDescription.length) {
      set(obOrderPropertyData, "description", sDescription);
    }

    const obOrderData: OrderRequestData | Record<string, any> = {
      order: { property: obOrderPropertyData },
      shipping_address: { id: this.obShippingData.id },
      billing_address: { id: this.obBillingData.id },
      user: {
        email: this.user.email,
        name: this.user.name,
      },
    };

    if (this.iPaymentMethodId) {
      this.$_.set(
        obOrderData,
        "order.payment_method_id",
        this.iPaymentMethodId
      );
    }

    this.bLoading = true;
    const obOrder = new Order(obOrderData);
    const obResponse = await obOrder
      .store()
      .then((response) => response?.getData())
      .catch((reason) => {
        this.evalResponse(reason);
      });

    this.bLoading = false;
    await this.cancel();

    if (obResponse) {
      this.evalResponse(obResponse);

      // Check by order secret key
      if (this.$_.has(obResponse, "data.key")) {
        // Emit event on order saved
        EventBus.emit("order.saved");

        // Reload cart data
        await CartModule.loadData();

        // Setup return url for payment
        this.obConfirmParams.return_url =
          window.location.origin +
          route("learning.order.confirm", {
            key: obResponse.data.key,
          });

        // Submit payment to stripe
        // @ts-ignore
        this.obElementPayment.submit();

        // await this.$router.push({
        //   name: "account-orders-view",
        //   params: { key: obResponse.data.key },
        // });
      }
    }
  }

  setAddressData(): void {
    this.obBillingData = this.getAddressData();
    this.obShippingData = this.getAddressData("shipping");
    this.nextStep();
    this.onSubmitStep();
  }

  getAddressData(sType: sAddressType = "billing"): Record<string, any> {
    const obEmpty: Record<string, any> = { type: sType };
    if (!this.arAddress || !this.arAddress.length) {
      return obEmpty;
    }

    const obAddressData = this.$_.find(this.arAddress, { type: sType });
    return obAddressData || obEmpty;
  }
}


import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { HTMLInputTypeAttribute } from "@/types/utils";

@Component
export default class FormFieldText extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly inputName!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop({ type: String, default: "text" })
  readonly type!: HTMLInputTypeAttribute;
  @Prop({ type: String, default: "label" }) readonly labelTag!: string;
  @Prop(String) readonly wrapClass!: string;
  @Prop(Boolean) readonly literal!: boolean;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(String) readonly rules!: string;

  get sRules(): string {
    if (!this.required && !this.rules) {
      return "";
    }

    const arRules: string[] = [];

    if (this.required) {
      arRules.push("required");
    }

    if (this.rules) {
      arRules.push(this.rules);
    }

    return arRules.join("|");
  }

  get sLabel(): string | undefined {
    if (!this.label) {
      return undefined;
    }

    return this.literal ? this.label : (this.$t(this.label) as string);
  }

  get sPlaceholder(): string | undefined {
    if (!this.placeholder) {
      return undefined;
    }

    return this.literal
      ? this.placeholder
      : (this.$t(this.placeholder) as string);
  }

  onInput(sValue: any): void {
    this.$emit("input", sValue);
  }
}
